// This tells the file to use this ts definition for its exports of react-x, and needs to have ///
// eslint-disable-next-line
/// <reference path="./react-x.d.ts" />

// Libraries
export {default as FlatList} from '@react-x/flat-list';
export {default as KeyboardAvoidingView} from '@react-x/keyboard-avoiding-view';
export {default as SafeAreaView} from '@react-x/safe-area-view';
export {default as ScrollView} from '@react-x/scroll-view';
export {default as SectionList} from '@react-x/section-list';
export {default as Switch} from '@react-x/switch';
export {
  Children,
  Dropdown,
  Emoji,
  Form,
  Hover,
  Lifecycle,
  Loading,
  Modal,
  Ref,
  Responsive,
  Tooltip,
  // @ts-expect-error package not typed
} from '@increment/components';

// Supermove
export {default as AudioPlayer} from './AudioPlayer';
export {default as Badge} from './Badge';
export {default as Calendar} from './Calendar';
export {default as Checkbox} from './Checkbox';
export {default as DataLoader, PageLoader} from './DataLoader';
export {default as DragAndDropList} from './DragAndDropList';
export {default as Drawer} from './Drawer';
export {default as ErrorView} from './ErrorView';
export {default as Field} from './Field';
export {default as Icon} from './Icon';
export * from './Icon';
export {default as LocationInput} from './LocationInput';
export {default as MapView} from './MapView';
export {default as Notification} from './Notification';
export {default as OfflineOverlay} from './OfflineOverlay';
export {default as Mutation} from './Mutation';
export {default as Popover} from './Popover';
export {default as Popup} from './Popup';
export {default as Query} from './Query';
export {default as ResponsivePopover} from './ResponsivePopover';
export {default as Space} from './Space';
export {default as Styled} from './Styled';
export {default as SupermoveLogo} from './SupermoveLogo';
export {default as Swiper} from './Swiper';
export {default as Tabs} from './Tabs';
export {default as UpdateChecker} from './UpdateChecker';
export {default as ToastContainer} from './ToastContainer';
export {default as EmojisPicker} from './EmojisPicker';

// Move to @increment/components
export {default as DevMenu} from './DevMenu';
export {default as ExternalLink} from './ExternalLink';
export {default as KeyboardView} from './KeyboardView';
export {default as Linkify} from './Linkify';
export {default as PermissionsView} from './PermissionsView';
export {default as SidebarV1} from './SidebarV1';
export {default as Sidebar} from './SidebarV2';
export {default as VideoPlayer} from './VideoPlayer';
export {default as ZoomView} from './ZoomView';
export {default as Spreadsheet} from './Spreadsheet';

// Move to @supermove/inputs
export {default as CameraInput} from './CameraInput';
export {default as CurrencyInput} from './CurrencyInput';
export {default as DateInput} from './DateInput';
export {default as SingleDatePicker} from './SingleDatePicker';
export {default as DropdownInput} from './DropdownInput';
export {default as DropdownInputVirtualized} from './DropdownInputVirtualized';
export {default as FileDragInput} from './FileDragInput';
export {default as MultiDropdownInput} from './MultiDropdownInput';
export {default as PdfView} from './PdfView';
export {default as PdfViewV2} from './PdfViewV2';
export {default as PercentInput} from './PercentInput';
export {default as PhoneInput} from './PhoneInput';
export {default as PreventPropagation} from './PreventPropagation';
export {default as RadioInput} from './RadioInput';
export {default as RichTextInputV1} from './RichTextInputV1';
export {default as RichTextInput} from './RichTextInput';
export {default as SignatureInput} from './SignatureInput';
export {default as TimeInput} from './TimeInput';
export {default as UnitInput} from './UnitInput';
export {default as CodeEditor} from './CodeEditor';
export {default as SanitizedHTML} from './SanitizedHTML';
// Move to @react-x
export {default as StatusBar} from './StatusBar';

// TODO(mark): Remove, only used for Manager.
export {Link} from 'react-router-dom';
